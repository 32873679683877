






import { Component, Prop, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import TemplateCategoryItem from "@/views/Communication/TemplateCategoryItem.vue";

@Component({
  components: { TemplateCategoryItem, FscSimpleCard, FscPageHeader },
})
export default class TemplateCategories extends Vue {
  public name = "TemplateCategories";

  @Prop({ type: Array, default: () => [] })
  public templateCategories!: Array<any>;
}

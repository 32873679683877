





















































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FscPageHeader from "@/components/FscPageHeader.vue";
import TemplateCategories from "@/views/Communication/TemplateCategories.vue";
import { IEmailTemplate } from "@/interfaces/IEmailTemplate.ts";
import EmailPlaceholders from "@/components/EmailPlaceholders.vue";
import TemplateForm from "@/components/TemplateForm.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import CreateCategoryButton from "@/components/Button/CreateCategoryButton.vue";
import { namespace } from "vuex-class";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import CardButtonForm from "@/components/CardButtonForm.vue";
import Actions from "@/components/Actions.vue";
import FuncMixins from "@/mixins/FuncMixins";
import FscModal from "@/components/Modal/FscModal.vue";

const emptyTemplate: IEmailTemplate = {
  id: 0,
  name: "",
  subject: "",
  body: "",
  templateCategoryId: null,
};

@Component({
  mixins: [FuncMixins],
  components: {
    CardButtonForm,
    FilterAndSearch,
    EmailPlaceholders,
    TemplateForm,
    TemplateCategories,
    FscPageHeader,
    RemoveButton,
    CreateButton,
    CreateCategoryButton,
    Actions,
    FscModal,
  },
})
export default class Templates extends mixins(FuncMixins) {
  public name = "Templates";
  @Prop({})
  private storeModule: any;

  public formIsNew = true;
  public showTemplateForm = false;
  public visibleCategoryForm = false;
  public searchTerm = "";
  public placeholder: any = null;

  protected deleteId = "delete-template-modal";
  protected currentCategory: any = null;

  /**store getters**/
  public get templateCategories() {
    return this.$store.getters[`${this.storeModule}/getTemplateCategories`];
  }

  public get getPlaceholders() {
    return this.$store.getters[`${this.storeModule}/getPlaceholders`] || [];
  }

  public get getSelectedTemplate() {
    return this.$store.getters[`${this.storeModule}/getSelectedTemplate`] || {};
  }

  public get isLoading() {
    return this.$store.getters[`${this.storeModule}/isLoading`] || {};
  }

  /**store actions**/
  public getTemplateCategoriesAction() {
    this.$store.dispatch(`${this.storeModule}/getTemplateCategoriesAction`);
  }

  public async removeSelectedCategoryAction(currentCategory: any) {
    await this.$store.dispatch(`${this.storeModule}/removeCategoryAction`, currentCategory);
    await this.getTemplateCategoriesAction();
  }

  public async removeSelectedTemplateAction(templateId: any) {
    await this.$store.dispatch(`${this.storeModule}/removeSelectedTemplateAction`, templateId);
    await this.getTemplateCategoriesAction();
  }

  public async createCategoryAction(e: any) {
    await this.$store.dispatch(`${this.storeModule}/createTemplateCategoryAction`, e);
    await this.getTemplateCategoriesAction();
  }

  public async createTemplateByCategoryAction(template: any) {
    await this.$store.dispatch(`${this.storeModule}/createTemplateByCategoryAction`, template);
    await this.getTemplateCategoriesAction();
  }

  public async editTemplateByCategoryAction(template: any) {
    this.$store.dispatch(`${this.storeModule}/editTemplateByCategoryAction`, template);
    await this.getTemplateCategoriesAction();
  }

  public getPlaceholdersAction() {
    this.$store.dispatch(`${this.storeModule}/getPlaceholdersAction`);
  }

  /**store mutations**/
  public setSelectedTemplateMutation(template: any) {
    this.$store.commit(`${this.storeModule}/SET_SELECTED_TEMPLATE`, template);
  }

  protected get isEmptyCategory() {
    const category = this.templateCategories.find((category: any) => category.id === this.currentCategory);
    return category?.templates?.length === 0;
  }

  protected get filteredCategories() {
    if (!this.searchTerm) return this.templateCategories || [];
    const term = this.searchTerm.toLowerCase();
    const originalDataCopy = Object.assign([], this.templateCategories);
    return originalDataCopy.filter((category: any) => {
      if (category?.name?.toLowerCase()?.indexOf(term) > -1) return category;
      if (category.templates?.length > 0) {
        const filteredTemplates = category.templates.filter((template: any) => {
          if (
            template?.name?.toLowerCase()?.indexOf(term) > -1 ||
            template?.subject?.toLowerCase()?.indexOf(term) > -1 ||
            template?.body?.toLowerCase()?.indexOf(term) > -1
          )
            return template;
        });
        if (filteredTemplates?.length > 0) {
          category.templates = filteredTemplates;
          return category;
        }
      }
    });
  }

  public async mounted() {
    await this.getTemplateCategoriesAction();
    await this.getPlaceholdersAction();
    this.$root.$on("template-form-show", (template: any) => {
      const templateCopy = Object.assign({}, template);
      this.formIsNew = false;
      this.setSelectedTemplateMutation(templateCopy);
      this.showTemplateForm = true;
    });
    this.$root.$on("active-category", (e: any) => {
      this.currentCategory = e;
    });
  }

  public onCreateTemplateClick() {
    let emptyTemplateClone = Object.assign({}, emptyTemplate);
    if (this.currentCategory) {
      emptyTemplateClone = { ...emptyTemplateClone, templateCategoryId: this.currentCategory };
    }
    this.setSelectedTemplateMutation(emptyTemplateClone);
    this.formIsNew = true;
    this.showTemplateForm = true;
    this.visibleCategoryForm = false;
  }

  public onDeleteOpenModal() {
    if (this.currentCategory && this.isEmptyCategory) {
      this.placeholder = this.$t("sidebar.template_category");
      this.$bvModal.show(this.deleteId);
      return;
    }
    const templateId = this.getSelectedTemplate.id;
    if (templateId) {
      this.placeholder = this.$t("sidebar.email_template");
      this.$bvModal.show(this.deleteId);
    }
  }

  public async onDeleteTemplateCategory() {
    if (this.currentCategory && this.isEmptyCategory) {
      await this.removeSelectedCategoryAction(this.currentCategory);
    }
  }

  public async onDeleteTemplate() {
    const templateId = this.getSelectedTemplate?.id;
    if (templateId) {
      this.setSelectedTemplateMutation(emptyTemplate);
      await this.removeSelectedTemplateAction(templateId);
      this.showTemplateForm = false;
    }
  }

  public async onCreateCategory(e: any) {
    const emptyTemplateClone = Object.assign({}, emptyTemplate);
    this.setSelectedTemplateMutation(emptyTemplateClone);
    if (!e) return;
    await this.createCategoryAction(e);
    this.onClose();
  }

  public onOpenFormCategory() {
    this.visibleCategoryForm = true;
    this.showTemplateForm = false;
  }

  public onClose() {
    this.visibleCategoryForm = false;
  }

  public async onTemplateSave(data: any) {
    const { editMode, template } = data;
    const emptyTemplateClone = Object.assign({}, emptyTemplate);
    this.setSelectedTemplateMutation(emptyTemplateClone);
    if (!editMode && template) {
      this.createTemplateByCategoryAction(template);
      this.showTemplateForm = false;
      return;
    }
    await this.editTemplateByCategoryAction(template);

    await this.getTemplateCategoriesAction();
    this.showTemplateForm = false;
  }

  public onCloseTemplateForm() {
    this.setSelectedTemplateMutation(emptyTemplate);
    this.showTemplateForm = false;
    this.$root.$emit("active-category", null);
  }

  public onSearch(e: any) {
    this.debouncer(() => {
      this.searchTerm = e.trim();
    });
  }

  public async deleteModalConfirmed() {
    await this.onDeleteTemplateCategory();
    await this.onDeleteTemplate();
  }
}





















import { Component, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: { FscSimpleCard },
})
export default class extends Vue {
  public name = "EmailPlaceholders";

  @Prop({ type: Array, default: () => [] })
  private placeholders?: any;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FscSimpleCard',{staticClass:"font-size-14 h-100 overflow-y-auto fsc-scroll"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('div',{staticClass:"font-weight-bold font-size-18"},[_vm._v(_vm._s(_vm.$t("sidebar.new_email_template")))])])],1),_c('b-row',{staticClass:"d-flex justify-content-between mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.email_template_category"))+" ")]),_c('fsc-multiselect',{attrs:{"name":"templateCategory","placeholder":_vm.$t('sidebar.email_template_category'),"options":_vm.templateCategories,"label":"name","track-by":"id","multiple":false,"show-labels":false,"searchable":true,"clearable":false,"preselect-first":_vm.formIsNew},on:{"option:selected":_vm.onSelectEmailTemplateCategory},model:{value:(_vm.selectedTemplateCategory),callback:function ($$v) {_vm.selectedTemplateCategory=$$v},expression:"selectedTemplateCategory"}})],1)],1),_c('b-row',{staticClass:"d-flex justify-content-between mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.email_template_name"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.template.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-input',{class:{
            'is-invalid': invalid,
          },model:{value:(_vm.getSelectedTemplate.name),callback:function ($$v) {_vm.$set(_vm.getSelectedTemplate, "name", $$v)},expression:"getSelectedTemplate.name"}})}}])})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.email_subject"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.template.subject},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var invalid = ref.invalid;
return _c('b-input',{class:{
            'is-invalid': invalid,
          },model:{value:(_vm.getSelectedTemplate.subject),callback:function ($$v) {_vm.$set(_vm.getSelectedTemplate, "subject", $$v)},expression:"getSelectedTemplate.subject"}})}}])})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('Validation',{attrs:{"validations":_vm.$v.template.body},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var invalid = ref.invalid;
return _c('Tinymce',{class:{
            'is-invalid-tinymce': invalid,
          },staticStyle:{"max-height":"227px"},model:{value:(_vm.getSelectedTemplate.body),callback:function ($$v) {_vm.$set(_vm.getSelectedTemplate, "body", $$v)},expression:"getSelectedTemplate.body"}})}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"md-auto btn-container d-flex justify-content-end"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":_vm.onAbort}}),_c('SendMailButton',{staticClass:"fsc-btn-border ml-2",attrs:{"disabled":!_vm.isValidData,"label":_vm.$t('general.save'),"icon-prefix":'fas',"icon-name":'fa-check',"icon-class":'font-color-fsc-text-apple-500'},on:{"click":_vm.onTemplateSave}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }